// Layout.js
import React,{useState} from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './navigation/NavBar';
import Banner from './banner/Banner';
import Footer from './footer/Footer';
import Dashboard from './dashboard/Dashboard';

const Layout = ({ isLogin, showLoginForm, showSignupForm }) => {

  return (
    <div>
      {/* <Dashboard></Dashboard> */}
    <Banner />
     <NavBar         showLoginForm={showLoginForm}
        showSignupForm={showSignupForm}
        isLogin={isLogin}></NavBar>
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;