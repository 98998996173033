// src/AboutSkillbridge.js
import React from 'react';
import styles from './Aboutus.module.css';
import crown from '../assets/crown.svg';
import medal from '../assets/medal.svg';
import mask from '../assets/theatermasks.svg';
import partnership from '../assets/patnership.svg'
import practical from '../assets/practicalSkills.svg';
import book from '../assets/book.svg';
import collabration from '../assets/collabration.svg';
import milestone from '../assets/milestone.svg';

const AboutUs = () => {
  return (
    <div className={styles.aboutusWrapper}>
    <div className={styles.container}>
    <header className={styles.header}>
        <h1 style={{marginBottom:'0px'}}>Online Courses on Design and Development</h1>
        <p>Welcome to our online course page, where you can enhance your skills in design and development. Choose from our carefully curated selection of courses designed to provide you with comprehensive knowledge and practical experience. Explore the courses below and find the perfect fit for your learning journey.</p>
      </header>

      <section className={styles.achievementsSection}>
        <h2>Achievements</h2>
        <p>
          Our commitment to excellence has led us to achieve significant milestones along our journey. 
          Here are some of our notable achievements.
        </p>
        <div className={styles.achievements}>
          <div className={styles.card}>
              <div className={styles.achievementsIcon}><span>
                <img src={crown} />
              </span></div>
            <h3>Trusted by Thousands</h3>
            <p>We have successfully served thousands of students, helping them unlock their potential and achieve their career goals.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={medal} />
              </span></div>
            <h3>Award-Winning Courses</h3>
            <p>Our courses have received recognition and accolades in the industry for their quality, depth of content, and effective teaching methodologies.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={mask} />
              </span></div>
            <h3>Positive Student Feedback</h3>
            <p>We take pride in the positive feedback we receive from our students, who appreciate the practicality and relevance of our course materials.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={partnership} />
              </span></div>
            <h3>Industry Partnerships</h3>
            <p>We have established strong partnerships with industry leaders, enabling us to provide our students with access to the latest tools and technologies.</p>
          </div>
        </div>
      </section>

      <section className={styles.goalsSection}>
        <h2>Our Goals</h2>
        <p>
          At SkillBridge, our goal is to empower individuals from all backgrounds to thrive in the world of design and development. 
          We believe that education should be accessible and transformative, enabling learners to pursue their passions and make a meaningful impact.
        </p>
        <div className={styles.goals}>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={practical} />
              </span></div>
            <h3>Provide Practical Skills</h3>
            <p>We focus on delivering practical skills that are relevant to the current industry demands. 
               Our courses are designed to equip learners with the knowledge and tools needed to excel in their chosen field.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={book} />
              </span></div>
            <h3>Foster Creative Problem-Solving</h3>
            <p>We encourage creative thinking and problem-solving abilities, allowing our students to tackle real-world challenges with confidence and innovation.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={collabration} />
              </span></div>
            <h3>Promote Collaboration and Community</h3>
            <p>We believe in the power of collaboration and peer learning. 
               Our platform fosters a supportive and inclusive community where learners can connect, share insights, and grow together.</p>
          </div>
          <div className={styles.card}>
          <div className={styles.achievementsIcon}><span>
                <img src={milestone} />
              </span></div>
            <h3>Stay Ahead of the Curve</h3>
            <p>The digital landscape is constantly evolving, and we strive to stay at the forefront of industry trends. 
               We regularly update our course content to ensure our students receive the latest knowledge and skills.</p>
          </div>
        </div>
      </section>

      <section className={styles.callToAction}>
          <div className={styles.joinUsContainer}>
            <div>
              <h2><span>Together</span>, let's shape the future of digital innovation</h2>
              <p>Join us on this exciting learning journey and unlock your potential in design and development.</p>
            </div>
            <div className={styles.joinBtnContainer}>
              <button className={styles.joinButton}>Join Now</button>
            </div>
          </div>
      </section>
    </div>
    </div>
  );
}

export default AboutUs;
