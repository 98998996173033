import React from 'react';
import styles from './CourseCard.module.css';
import Curriculum from '../curriculum/Curriculum';
import { Link } from 'react-router-dom';
const CourseCard = ({ id, title, description, duration, level, instructor, images, curriculum }) => {
  return (
    <div className={styles.courseCard}>
          <h2 style={{marginBottom:'5px',marginTop:'0px'}}>{title}</h2>
      <div className={styles.courseHeader}>
        <div className={styles.courseInfo}>
          <p>{description}</p>
        </div>
        <div className={styles.benefitsView}>
          <Link to={`/course/${id}`}>
                            <button>
                                View Course
                            </button>
                            </Link>
        </div>
      </div>
      <div className={styles.imagesContainer}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`${title} ${index + 1}`} />
        ))}
      </div>
      <div className={styles.courseDetails}>
        <div className={styles.courseMeta}>
          <span className={styles.courseDetails}>{duration}</span>
          <span className={styles.courseLevel}>{level}</span>
        </div>
        <div className={styles.instructor}>By {instructor}</div>
      </div>

      {/* <Curriculum curriculum={curriculum} /> */}
    </div>
  );
};

export default CourseCard;
