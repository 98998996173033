
import styles from './Home.module.css';
import { faBolt, faArrowRight } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import frontEnd from '../assets/frontEnd.png';
import graphicDesign from '../assets/graphicDesign.png';
import webDesign from '../assets/webDesign.png';
import advanceJavascript from '../assets/advanceJavascript.png';
import splashIcon from '../assets/splash.png';
import profileImage from '../assets/profile.png';
import Pricing from '../pricing/Pricing';
// import Carousel from '../carousel/Carousel';
import Faq from '../faq/Faq';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Home() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 767, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

      const sliderImageUrl = [
        //First image url
        {
          url:
            "https://i2.wp.com/www.geeksaresexy.net/wp-content/uploads/2020/04/movie1.jpg?resize=600%2C892&ssl=1"
        },
        {
          url:
            "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-kids-movies-2020-call-of-the-wild-1579042974.jpg?crop=0.9760858955588091xw:1xh;center,top&resize=480:*"
        },
        //Second image url
        {
          url:
            "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-movies-for-kids-2020-sonic-the-hedgehog-1571173983.jpg?crop=0.9871668311944719xw:1xh;center,top&resize=480:*"
        },
        //Third image url
        {
          url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQS82ET2bq9oTNwPOL8gqyoLoLfeqJJJWJmKQ&usqp=CAU"
        },
      
        //Fourth image url
      
        {
          url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTdvuww0JDC7nFRxiFL6yFiAxRJgM-1tvJTxA&usqp=CAU"
        }
      ];

    return (
        <>
            <div className={styles.homeWrapper}>
                <div className={styles.homeMainScreen}>
                    <div className={styles.homeTitle}>
                        <img className={styles.splashIcon} src={splashIcon} alt="splash-icon" />
                        <span className={styles.thunderIcon}>
                            <FontAwesomeIcon style={{marginLeft:'14px'}} icon={faBolt} />
                        </span>
                        <span className={styles.tagLine}>
                            Unlock
                            <span className={styles.tagLine1}>
                                Your Creative Potential
                            </span>
                        </span>
                    </div>
                    <div className={styles.homeSubtitle}>
                        <span>
                            Easy Learn Easy Earn - Empowering Learning, Enabling Earning
                        </span>
                    </div>
                    <div className={styles.homeDescription}>
                        <span>
                            Welcome to Easy Learn Easy Earn, where learning meets earning in an innovative digital platform designed to transform education into a rewarding experience.
                        </span>
                    </div>
                    <div className={styles.homeButtons}>
                        <button className={styles.homeBtn1}>
                            Explore E-books
                        </button>
                        {/* <button className={styles.homeBtn2}>
                            View Pricing
                        </button> */}
                    </div>
                </div>
                {/* <div className={styles.clients}>
                    <div>
                        <span>
                            Zapier
                        </span>
                    </div>
                    <div>
                        <span>
                            spotify
                        </span>
                    </div>
                    <div>
                        <span>
                            zoom
                        </span>
                    </div>
                    <div>
                        <span>
                            amazon
                        </span>
                    </div>
                </div> */}
                <div className={styles.videoContainer}>

                </div>
                <div className={styles.benefitsContainer}>
                    <div className={styles.benefitsText}>
                        <span>Benefits</span>
                    </div>
                    <div className={styles.benefitsDescriptionWrapper}>
                        <div className={styles.description}>
                            <span>Welcome to Easy Learn Easy Earn, where knowledge meets opportunity. Explore our platform to discover a world of learning and earning possibilities</span>
                        </div>
                        <div className={styles.benefitsView}>
                            <button>
                                View All
                            </button>
                        </div>
                    </div>
                    <div className={styles.benefitsGrid}>
                        <div className={`${styles.one} ${styles.item}`}>
                            <div className={styles.innerSection}>
                            <div className={styles.innerSectionTitle}>

                                <span className={styles.gridTitle}>
                                    Skill Enhancement
                                </span>
                                <span className={styles.gridNum}>
                                    01
                                </span>
                                </div>

                                <span className={styles.gridDescription}>
                                    Each e-book is designed to enhance critical skills such as logical thinking, problem-solving, and critical thinking. These are essential skills that will help students excel academically and in their future careers.
                                </span>
                                <div className={styles.benefitsViewMore}>
                                    <span className={styles.arrowIcon}>
                                        <span>
                                            <FontAwesomeIcon color='#FF9500' icon={faArrowRight} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.two} ${styles.item}`}>
                            <div className={styles.innerSection}>
                            <div className={styles.innerSectionTitle}>

                                <span className={styles.gridTitle}>
                                    Bridging the Gap Between Knowledge and Money
                                </span>
                                <span className={styles.gridNum}>
                                    02
                                </span>
                                </div>

                                <span className={styles.gridDescription}>
                                    By combining learning with earning, we bridge the gap between education and financial independence. Students can gain valuable knowledge while also earning money to support their studies.
                                </span>
                                <div className={styles.benefitsViewMore}>
                                    <span className={styles.arrowIcon}>
                                        <span>
                                            <FontAwesomeIcon color='#FF9500' icon={faArrowRight} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.three} ${styles.item}`}>
                            <div className={styles.innerSection}>
                            <div className={styles.innerSectionTitle}>

                                <span className={styles.gridTitle}>
                                    Earn While You Learn
                                </span>
                                <span className={styles.gridNum}>
                                    03
                                </span>
                                </div>

                                <span className={styles.gridDescription}>
                                    Our unique program allows students to earn 5 Rs for each successfully completed and uploaded task. This not only motivates students to engage with the material but also provides them with a tangible reward for their efforts.
                                </span>
                                <div className={styles.benefitsViewMore}>
                                    <span className={styles.arrowIcon}>
                                        <span>
                                            <FontAwesomeIcon color='#FF9500' icon={faArrowRight} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.four} ${styles.item}`}>
                            <div className={styles.innerSection}>
                                <div className={styles.innerSectionTitle}>
                                    <span className={styles.gridTitle}>
                                        Learn at Your Own Pace
                                    </span>
                                    <span className={styles.gridNum}>
                                        04
                                    </span>
                                </div>
                               
                                <span className={styles.gridDescription}>
                                    Students can read and learn at their own convenience, making it easier to balance studies with other responsibilities. This flexibility ensures a stress-free learning experience.
                                </span>
                                <div className={styles.benefitsViewMore}>
                                    <span className={styles.arrowIcon}>
                                        <span>
                                            <FontAwesomeIcon color='#FF9500' icon={faArrowRight} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className={styles.courses}>
                        <div className={styles.benefitsText}>
                            <span>Our Courses</span>
                        </div>
                        <div className={styles.benefitsDescriptionWrapper}>
                            <div className={styles.description}>
                                <span>Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.</span>
                            </div>
                            <div className={styles.benefitsView}>
                                <button>
                                    View All
                                </button>
                            </div>
                        </div>
                        <div className={styles.courseList}>
                            <div className={styles.courseCard}>
                                <img src={advanceJavascript} alt="Web Design Fundamentals" />
                                <div className={styles.courseInfo}>
                                    <div className={styles.courseMeta}>
                                        <div className={styles.courseDetails}>
                                            <span>4 Weeks</span><span>Beginner</span>
                                        </div>
                                        <span className={styles.instructor}>By John Smith</span>
                                    </div>
                                    <h2>Web Design Fundamentals</h2>
                                    <p>Learn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills to create visually appealing and user-friendly websites.</p>
                                    <button>Get it Now</button>
                                </div>
                            </div>
                            <div className={styles.courseCard}>
                                <img src={frontEnd} alt="Web Design Fundamentals" />
                                <div className={styles.courseInfo}>
                                    <div className={styles.courseMeta}>
                                        <div className={styles.courseDetails}>
                                            <span>4 Weeks</span><span>Beginner</span>
                                        </div>
                                        <span className={styles.instructor}>By John Smith</span>
                                    </div>
                                    <h2>Web Design Fundamentals</h2>
                                    <p>Learn the fundamentals ofLearn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills  web design, including HTML, CSS, and responsive design principles. Develop the skills to create visually appealing and user-friendly websites.</p>
                                    <button>Get it Now</button>
                                </div>
                            </div>
                            <div className={styles.courseCard}>
                                <img src={graphicDesign} alt="Web Design Fundamentals" />
                                <div className={styles.courseInfo}>
                                    <div className={styles.courseMeta}>
                                        <div className={styles.courseDetails}>
                                            <span>4 Weeks</span><span>Beginner</span>
                                        </div>
                                        <span className={styles.instructor}>By John Smith</span>
                                    </div>
                                    <h2>Web Design Fundamentals</h2>
                                    <p>Learn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills to create visually appealing and user-friendly websites.</p>
                                    <button>Get it Now</button>
                                </div>
                            </div>
                            <div className={styles.courseCard}>
                                <img src={advanceJavascript} alt="Web Design Fundamentals" />
                                <div className={styles.courseInfo}>
                                    <div className={styles.courseMeta}>
                                        <div className={styles.courseDetails}>
                                            <span>4 Weeks</span><span>Beginner</span>
                                        </div>
                                        <span className={styles.instructor}>By John Smith</span>
                                    </div>
                                    <h2>Web Design Fundamentals</h2>
                                    <p>Learn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills to create visually appealing and user-friendly websites.</p>
                                    <button>Get it Now</button>
                                </div>
                            </div>
                            <div className={styles.courseCard}>
                                <img src={webDesign} alt="Web Design Fundamentals" />
                                <div className={styles.courseInfo}>
                                    <div className={styles.courseMeta}>
                                        <div className={styles.courseDetails}>
                                            <span>4 Weeks</span>
                                            <span>Beginner</span>
                                        </div>
                                        <span className={styles.instructor}>By John Smith</span>
                                    </div>
                                    <h2>Web Design Fundamentals</h2>
                                    <p>Learn the fundamentals of web design, including HTML, CSS, and responsive design principles. DevelopLearn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills to cr. Develop the skills to create visually appealing and user-friendly websites.</p>
                                    <button>Get it Now</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={styles.courses}>
                        <div className={styles.benefitsText}>
                            <span>Our Testimonials</span>
                        </div>
                        <div className={styles.benefitsDescriptionWrapper}>
                            <div className={styles.description}>
                                <span>Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.</span>
                            </div>
                            <div className={styles.benefitsView}>
                                <button>
                                    View All
                                </button>
                            </div>
                        </div>
                        <div>

                    {/* <Carousel responsive={responsive} showDots={true} centerMode={true} removeArrowOnDeviceType={["tablet", "mobile"]}>
                        <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                    </Carousel> */}

<Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {/* {sliderImageUrl.map((imageUrl, index) => {
          return (
            <div className="slider" key={index}>
              <img src={imageUrl.url} alt="movie" />
            </div>
          );
        })} */}
                                    <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
      </Carousel>

                        {/* <Carousel interval={3000} showDots={true} showButtons={true}>
                        <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
            </Carousel> */}



                            {/* <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div>
                            <div className={styles.testimonialCard}>
                                <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                <div className={styles.testimonialFooterWrapper}>
                                    <div className={styles.testimonialFooter}>
                                        <img src={profileImage} alt="Sarah L" />
                                        <span>Sarah L</span>
                                    </div>
                                    <button className={styles.readMore}>Read Full Story</button>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </div>
                {/* <Pricing /> */}
                <Faq />
            </div>
        </>
    )
}

export default Home;