import React, { useState } from 'react';
import Accordion from '../accordian/Accordion';
import styles from './Faq.module.css';

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: 'Can I enroll in multiple courses at once?',
      answer: 'Absolutely! You can enroll in multiple courses simultaneously and access them at your convenience.'
    },
    {
      question: 'What kind of support can I expect from instructors?',
      answer: 'You can expect priority support from instructors in the Pro Plan, including answers to your questions and guidance on course materials.'
    },
    {
      question: 'Are the courses self-paced or do they have specific start and end dates?',
      answer: 'Our courses are self-paced, allowing you to start and finish at your own convenience.'
    },
    {
      question: 'Are there any prerequisites for the courses?',
      answer: 'Some advanced courses may have prerequisites. Check the course details for more information.'
    },
    {
      question: 'Can I download the course materials for offline access?',
      answer: 'Yes, you can download course materials for offline access.'
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className={styles.faq}>
      <div className={styles.faqContainer}>
      <div className={styles.header}>
        <h1>Frequently Asked Questions</h1>
        <p>Still have any questions? Contact our team via support@skillbridge.com</p>
        <button className={styles.fqaBtn}>See All FAQ's</button>
      </div>
      <div className={styles.accordions}>
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            title={faq.question}
            content={faq.answer}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
      </div>
    </section>
  );
};

export default Faq;
