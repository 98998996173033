import React from 'react';
import styles from './ContactUs.module.css';

const ContactUS = () => {
  return (
    <>
     <header className={styles.header}>
        <h1 style={{marginBottom:'0px'}}>Contact Us</h1>
        <p>Welcome to our online course page, where you can enhance your skills in design and development. Choose from our carefully curated selection of courses designed to provide you with comprehensive knowledge and practical experience. Explore the courses below and find the perfect fit for your learning journey.</p>
      </header>
   
    <div className={styles.contactFormContainer}>
    <div className={styles.form}>
      <div className={styles.row}>
        <div className={styles.inputContainer}>
        <span>First Name</span>
        <input type="text" placeholder="Enter First Name" className={styles.input} />
        </div>
        <div className={styles.inputContainer}>
<span>Last Name</span>
        <input type="text" placeholder="Enter Last Name" className={styles.input} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.inputContainer}>
            <span>Email</span>
        <input type="email" placeholder="Enter your Email" className={styles.input} />
        </div>
        <div className={styles.inputContainer}>
            <span>Phone</span>
        <input type="tel" placeholder="Enter Phone Number" className={styles.input} />
        </div>
      </div>
      <div className={styles.inputContainer}>
<span>Subject</span>
      <input type="text" placeholder="Enter your Subject" className={styles.input} />
      </div>
      <textarea placeholder="Enter your Message here..." className={styles.textarea}></textarea>
      <div className={styles.buttonWrapper}>
      <button className={styles.button}>Send Your Message</button>
      </div>
    </div>
    {/* <div className={styles.contactInfo}>
      <div className={styles.infoItem}>
        <span className={styles.icon}>📧</span>
        <span>support@skillbridge.com</span>
      </div>
      <div className={styles.infoItem}>
        <span className={styles.icon}>📞</span>
        <span>+91 00000 00000</span>
      </div>
      <div className={styles.infoItem}>
        <span className={styles.icon}>📍</span>
        <span>Some Where in the World</span>
      </div>
      <div className={styles.socialProfiles}>
        <div>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <span className={styles.icon}>📘</span>
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <span className={styles.icon}>🐦</span>
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <span className={styles.icon}>🔗</span>
        </a>
        </div>
        <div>
            Social Profiles
        </div>

      </div>
    </div> */}
  </div>
  </>
  );
};

export default ContactUS;
