import styles from './Login.module.css';
// import Banner from '../banner/Banner';
// import NavBar from '../navigation/NavBar';
import profileImage from '../assets/profile.png';
import React, { useState } from 'react';

// import Home from '../home/Home';
// import Courses from '../courses/Courses';

function LoginForm({ isLogin }) {
    return (
        <div className={`${styles.authContainer} ${isLogin ? '' : styles.flip}`}>
            <div>
            <div className={styles.loginForm}>
                <h2>Login</h2>
                <p>Welcome back! Please log in to access your account.</p>
                <form>
                    <label>Email</label>
                    <input type="email" placeholder="Enter your Email" />
                    <label>Password</label>
                    <input type="password" placeholder="Enter your Password" />
                    <div className={styles.formOptions}>
                        <div>
                            <input type="checkbox" />
                            <label style={{ fontWeight: '400' }}>Remember Me</label>
                        </div>
                        <a href="/forgot-password">Forgot Password?</a>
                    </div>
                    <button type="submit">Login</button>
                </form>
                <p>Don’t have an account? <a href="/sign-up">Sign Up</a></p>
            </div>
            </div>
<div>
<div  className={styles.signupForm}>
                <h2>Sign Up</h2>
                <p>Create an account to unlock exclusive features</p>
                <form>
                    <label>Full Name</label>
                    <input type="email" placeholder="Enter your Name" />
                    <label>Email</label>
                    <input type="email" placeholder="Enter your Email" />
                    <label>Password</label>
                    <input type="password" placeholder="Enter your Password" />
                    <div className={styles.formOptions}>
                        <div>
                            <input type="checkbox" />
                            <label style={{ fontWeight: '400', fontSize: '14px' }}>I agree with <u>Terms of use</u> and <u>Privacy Policy</u></label>
                        </div>
                    </div>
                    <button type="submit">Sign Up</button>
                </form>
                <p>Already have an account? <a href="/sign-up">Login</a></p>
            </div>
</div>

        </div>
    );
}

function Testimonials() {
    return (
        <div className={styles.testimonials}>
            <div className={styles.testimonialsContainer}>
                <h2 style={{ marginBottom: '0px', marginTop: '50px' }}>Students Testimonials</h2>
                <p>Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.</p>
                <div className={styles.testimonialCard}>
                    <p className={styles.description}>The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                    <div className={styles.testimonialFooterWrapper}>
                        <div className={styles.testimonialFooter}>
                            <img src={profileImage} alt="Sarah L" />
                            <span>Sarah L</span>
                        </div>
                        <button className={styles.readMore}>Read More</button>
                    </div>
                </div>
                <div className={styles.testimonialNavigation}>
                    <button>&larr;</button>
                    <button>&rarr;</button>
                </div>
            </div>
        </div>
    );
}



function Login({ isLogin }) {
    return (
        <div className={styles.app}>
            {/* <Banner></Banner>
            <NavBar showLoginForm={showLoginForm} showSignupForm={showSignupForm} isLogin={isLogin}></NavBar> */}
            <div className={styles.container}>
                <Testimonials />
                <LoginForm isLogin={isLogin} />
            </div>
            {/* <Home/> */}

            {/* <Courses/> */}
        </div>
    )
}

export default Login;
