import React, { useState } from 'react';
import { Link, BrowserRouter } from 'react-router-dom';
import styles from './NavBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCoffee } from '@fortawesome/fontawesome-free-solid'
import logo from '../assets/Logo.svg';
import menuIcon from '../assets/menuIcon.svg';

const NavBar = ({ showLoginForm, showSignupForm, isLogin }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [activeItem, setActiveItem] = useState('Home');
    const toggleMenu = () => {
        setShowMenu(!showMenu);
        console.log('Hello')
    };

    const handleItemClick = (label) => {
        setActiveItem(label);
    };

    // const menuItem = (label, path) => (
    //     <li className={styles.menuItem}>
          
    //             <Link className={`${styles.menuItemLink} ${label === activeItem ? styles.activeItem : styles.notActiveItem}`} to={path} onClick={() => handleItemClick(label)}>
    //                 {label}
    //             </Link>
          
    //     </li>
    // );


    const menuItem = (label, path, isButton = false, buttonProps = {}) => (
        <li className={styles.menuItem}>
            {isButton ? (
                <Link to={path}>
                    <button
                        className={buttonProps.className}
                        onClick={buttonProps.onClick}
                    >
                        {label}
                    </button>
                </Link>
            ) : (
                <Link
                    className={`${styles.menuItemLink} ${label === activeItem ? styles.activeItem : styles.notActiveItem}`}
                    to={path}
                    onClick={() => handleItemClick(label)}
                >
                    {label}
                </Link>
            )}
        </li>
    );



    return (
        <div>
            <nav className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.menuIcon}>
                       <img src={logo} alt="logo" />
                    </div>
                    <ul className={styles.menuItems}>
                        {menuItem('Home', '/')}
                        {menuItem('Courses', '/courses')}
                        {menuItem('About Us', '/aboutus')}
                        {/* {menuItem('Pricing', '/pricing')} */}
                        {menuItem('Contact Us', '/contact-us')}
                    </ul>
                </div>
                <div className={styles.buttons}>
                 
                    <div className={styles.loginSignupButtons}>
                        <Link to={'login'}> <button className={`${styles.signupButton} ${!isLogin ? styles.activeButton : styles.notActiveButton}`} onClick={!isLogin ? null : showSignupForm}>Sign Up</button></Link>
                        <Link to={'login'}><button className={`${styles.loginButton} ${isLogin ? styles.activeButton : styles.notActiveButton}`} onClick={isLogin ? null : showLoginForm}>Login</button></Link>
                    </div> 
                 
                            
                    
                  
                    <div className={styles.menuButton} onClick={toggleMenu}>
                        {/* <FontAwesomeIcon icon={faBars} size='2x'  /> */}
                        <img src={menuIcon} alt="logo" />
                    </div>
                </div>

            </nav>
            {showMenu && (
                <ul className={showMenu ? styles.dropdownMenuVisible : styles.dropdownMenuHidden}>
                        {menuItem('Home', '/')}
                        {menuItem('Courses', '/courses')}
                        {menuItem('About Us', '/aboutus')}
                        {/* {menuItem('Pricing', '/pricing')} */}
                        {menuItem('Contact Us', '/contact-us')}
                        {menuItem('Sign Up', '/login', true, {
                    className: `${styles.signupButton} ${!isLogin ? styles.activeButton : styles.notActiveButton}`,
                    onClick: !isLogin ? null : showSignupForm
                })}
                {menuItem('Login', '/login', true, {
                    className: `${styles.loginButton} ${isLogin ? styles.activeButton : styles.notActiveButton}`,
                    onClick: isLogin ? null : showLoginForm
                })}
                </ul>
            )}
        </div>
    );
};

export default NavBar;
