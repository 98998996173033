import logo from './logo.svg';
import styles from './App.module.css';
import React, { useState } from 'react';
import Login from './login/Login'
import Courses from './courses/Courses';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CourseDetail from './course-detail/CourseDetail';
import AboutUs from './about-us/AboutUs';
import Layout from './Layout';
import Home from './home/Home';
import ContactUs from './contact-us/ContactUs';
function App() {

  const [isLogin, setIsLogin] = useState(true);

  const showLoginForm = () => {
    setIsLogin(true);
  };

  const showSignupForm = () => {
    setIsLogin(false);
  };

  return (
<div>
<div className={styles.mainAppContainer}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout isLogin={isLogin}
            showLoginForm={showLoginForm}
            showSignupForm={showSignupForm} />} >
            <Route index element={<Home />} />
            <Route path="login" element={<Login isLogin={isLogin} />} />
            <Route path="courses" element={<Courses />} />
            <Route path="course/:id" element={<CourseDetail />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </div>
</div>

  );
}

export default App;
