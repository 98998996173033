import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationArrow, faEnvelope } from '@fortawesome/fontawesome-free-solid';
const Footer = () => {
    return (
        <footer className={styles.footerWrapper}>
            <div className={styles.footer}>
                <div className={styles.contactInfo}>
                    <span> <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px' }} /> hello@easylearneasyearn.com</span>
                    <span> <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }} /> -91 91813 23 2309</span>
                    <span> <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: '5px' }} /> Somewhere in the World</span>
                </div>
                <div className={styles.footerItemsWrapper}>
                    <div className={styles.aboutUsWrapper}>
                        <div>
                            <span>About US</span>
                        </div>
                        <div className={styles.aboutUsMenu}>
                            <ul>Company</ul>
                            <ul>Achievements</ul>
                            <ul>Our Goals</ul>
                            <ul>Our FAQ</ul>
                            <ul>Our Testimonials</ul>
                        </div>
                    </div>
                    <div className={styles.socialProfiles}>
                        <div className={styles.title}>
                            <span>Social Profiles</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'10px'}}>
                            <div className={styles.facebook}>
                                F
                            </div>
                            <div className={styles.facebook}>
                                T
                            </div>
                            <div className={styles.facebook}>
                                I
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>
                <p>© 2024 Easy Learn Easy Earn. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;