import React from 'react';
import styles from './Courses.module.css';
import CourseCard from '../course-card/CourseCard';
import advJava from '../assets/advanceJavascript.png';
import frontEnd from '../assets/frontEnd.png';
import graphicDesign from '../assets/graphicDesign.png'

const Courses = () => {
  const courses = [
    {
      id: 1,
      title: "Web Design Fundamentals",
      description: "Learn the fundamentals of web design, including HTML, CSS, and responsive design principles. Develop the skills to create visually appealing and user-friendly websites.",
      duration: "4 Weeks",
      level: "Beginner",
      instructor: "John Smith",
      images: [
        advJava,
        frontEnd,
        graphicDesign
      ],
      curriculum: [
        "Introduction to HTML",
        "Styling with CSS",
        "Introduction to Responsive Design",
        "Design Principles for Web",
        "Building a Basic Website"
      ]
    },
    {
      id: 2,
      title: "UI/UX Design",
      description: "Master the art of creating intuitive user interfaces (UI) and enhancing user experiences (UX). Learn design principles, wireframing, prototyping, and usability testing techniques.",
      duration: "6 Weeks",
      level: "Intermediate",
      instructor: "Emily Johnson",
      images: [
        advJava,
        frontEnd,
        graphicDesign
      ],
      curriculum: [
        "Introduction to UI/UX Design",
        "User Research and Personas",
        "Wireframing and Prototyping",
        "Visual Design and Branding",
        "Usability Testing and Iteration"
      ]
    },
    {
      id: 3,
      title: "UI/UX Design",
      description: "Master the art of creating intuitive user interfaces (UI) and enhancing user experiences (UX). Learn design principles, wireframing, prototyping, and usability testing techniques.",
      duration: "6 Weeks",
      level: "Intermediate",
      instructor: "Emily Johnson",
      images: [
        advJava,
        frontEnd,
        graphicDesign
      ],
      curriculum: [
        "Introduction to UI/UX Design",
        "User Research and Personas",
        "Wireframing and Prototyping",
        "Visual Design and Branding",
        "Usability Testing and Iteration"
      ]
    },    {
      id: 4,
      title: "UI/UX Design",
      description: "Master the art of creating intuitive user interfaces (UI) and enhancing user experiences (UX). Learn design principles, wireframing, prototyping, and usability testing techniques.",
      duration: "6 Weeks",
      level: "Intermediate",
      instructor: "Emily Johnson",
      images: [
        advJava,
        frontEnd,
        graphicDesign
      ],
      curriculum: [
        "Introduction to UI/UX Design",
        "User Research and Personas",
        "Wireframing and Prototyping",
        "Visual Design and Branding",
        "Usability Testing and Iteration"
      ]
    },
    {
      id: 5,
      title: "UI/UX Design",
      description: "Master the art of creating intuitive user interfaces (UI) and enhancing user experiences (UX). Learn design principles, wireframing, prototyping, and usability testing techniques.",
      duration: "6 Weeks",
      level: "Intermediate",
      instructor: "Emily Johnson",
      images: [
        advJava,
        frontEnd,
        graphicDesign
      ],
      curriculum: [
        "Introduction to UI/UX Design",
        "User Research and Personas",
        "Wireframing and Prototyping",
        "Visual Design and Branding",
        "Usability Testing and Iteration"
      ]
    }
  ];

  return (
    <div className={styles.coursesContainer}>
      <header className={styles.header}>
        <h1 style={{marginBottom:'0px'}}>Online Courses on Design and Development</h1>
        <p>Welcome to our online course page, where you can enhance your skills in design and development. Choose from our carefully curated selection of courses designed to provide you with comprehensive knowledge and practical experience. Explore the courses below and find the perfect fit for your learning journey.</p>
      </header>
      <div className={styles.courseList}>
        {courses.map((course, index) => (
          <CourseCard key={index} {...course} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
