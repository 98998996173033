import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './CourseDetail.module.css';
import { faBolt, faUser, faClock, faCheckCircle, faStar, faFile } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import videoPlaceholder from '../assets/advanceJavascript.png';
import lessonVideo from '../assets/lesson-video.mp4'; // Example video, replace with actual video source
import lessonVideo1 from '../assets/lesson-video1.mp4'; // Example video, replace with actual video source
import lessonVideo2 from '../assets/lesson-video2.mp4'; // Example video, replace with actual video source
import clock from '../assets/clock.svg';

const CourseDetail = () => {
  const { id } = useParams();
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedLessonVideo, setSelectedLessonVideo] = useState(null);

  const courses = [
    {
      id: 1,
      category: "Graphic Design",
      title: "Beginner Adobe Illustrator for Graphic Design",
      description: "Design tutorial will help you learn quickly and thoroughly orem ipsumor lipsum as it is sometime",
      instructor: {
        name: "Arian Hok",
        avatar: "https://via.placeholder.com/50"
      },
      lessons: 19,
      duration: "10h 30m",
      students: 18,
      rating: 4.8,
      price: 25.00,
      originalPrice: 84.99,
      previewImage: "https://via.placeholder.com/300x200",
      fullDescription: "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.",
      learningPoints: [
        "Become a UX designer.",
        "You will be able to add UX designer to your CV",
        "Become a UI designer.",
        "Build & test a full website design.",
        "Create your first UX brief & persona.",
        "How to use premade UI kits."
      ],
      requirements: "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.",
      estimatedSeat: 250,
      joined: 190,
      language: "English"
    },
    {
      id: 2,
      category: "Web Development",
      title: "Complete Web Development Bootcamp",
      description: "Learn to build responsive websites using HTML, CSS, and JavaScript",
      instructor: {
        name: "Sarah Johnson",
        avatar: "https://via.placeholder.com/50"
      },
      lessons: 25,
      duration: "15h 45m",
      students: 32,
      rating: 4.9,
      price: 49.99,
      originalPrice: 199.99,
      previewImage: "https://via.placeholder.com/300x200",
      fullDescription: "This comprehensive bootcamp covers all aspects of web development, from basic HTML to advanced JavaScript frameworks.",
      learningPoints: [
        "Master HTML5 and CSS3",
        "Learn JavaScript and ES6+",
        "Understand responsive design principles",
        "Build dynamic web applications",
        "Work with popular frameworks like React",
        "Deploy your websites to the web"
      ],
      requirements: "Basic computer skills and a passion for learning web development. No prior coding experience necessary.",
      estimatedSeat: 300,
      joined: 210,
      language: "English"
    }
  ];

  const [selectedCourse, setSelectedCourse] = useState(courses[0]);

  const course = {
    title: "UI/UX Design Course",
    description: "Welcome to our UI/UX Design course! This comprehensive program will equip you with the knowledge and skills to create exceptional user interfaces (UI) and enhance user experiences (UX). Dive into the world of design thinking, wireframing, prototyping, and usability testing. Below is an overview of the curriculum",
    lessons: [
      {
        title: "Introduction to UI/UX Design",
        lessonNumber: '01',
        details: [
          {id: 1, name: "Understanding UI/UX Design Principles", duration: "45 Minutes",video: lessonVideo},
          {id: 2, name: "Importance of User-Centered Design", duration: "1 Hour",video: lessonVideo1 },
          {id: 3, name: "The Role of UI/UX Design in Product Development", duration: "45 Minutes" ,video: lessonVideo2},
        ],
      },
      {
        title: "User Research and Analysis",
        lessonNumber: '02',
        details: [
          { id: 4,name: "Conducting User Research and Interviews", duration: "1 Hour" },
          { id: 5, name: "Analyzing User Needs and Behavior", duration: "1 Hour" },
          {  id: 6,name: "Creating User Personas and Scenarios", duration: "45 Minutes" },
        ],
      },
    ],
  };
  const handleLessonClick = (detail) => {
    setSelectedDetail(detail.id);
    setSelectedLessonVideo(detail.video);
    console.log()
  };
  const rating = 4.8;
  const totalStars = 5;

  const [activeTab, setActiveTab] = useState('CourseInfo');
  return (
    // <div className={styles.courseWrapper}>
    // <div className={styles.courseDetail}>
    //   <header className={styles.header}>
    //     <h1>{course.title}</h1>
    //     <p>{course.description}</p>
    //   </header>
    //   <div className={styles.videoContainer}>
    //     {selectedDetail ? (
    //       <video controls src={selectedLessonVideo}>
    //         Your browser does not support the video tag.
    //       </video>
    //     ) : (
    //       <img src={videoPlaceholder} alt="Course Video Placeholder" />
    //     )}
    //   </div>
    //   <div className={styles.lessonContainer}>
    //     {course.lessons.map((lesson, lessonIndex) => (
    //       <div key={lessonIndex} className={styles.lesson}>
    //         <div className={styles.lessonNumber}>{lesson.lessonNumber}</div>
    //         <h2>{lesson.title}</h2>
    //         {lesson.details.map((detail, detailIndex) => (
    //           <div
    //             key={detailIndex}
    //             className={`${styles.lessonDetail} ${selectedDetail === detail.id ? styles.active : ''}`}
    //             onClick={() => handleLessonClick(detail)
    //             }
    //           >
    //             <div>
    //               <div className={styles.name}>{detail.name}</div>
    //               <div className={styles.lessonCount}>Lesson  {detailIndex + 1}</div>
    //             </div>
    //             <div className={`${styles.duration} ${selectedDetail === detail.id ? styles.durationSelected : styles.duartionDeselected}`}><div><img src={clock} /> <span>{detail.duration}</span></div></div>
    //           </div>
    //         ))}
    //       </div>
    //     ))}
    //   </div>
    // </div>
    // </div>
<div className={styles.mainContainer}>
  <div className={styles.headerSection}>
      <div className={styles.courseDetailsWrapper}>
          <div className={styles.courseDetails}>
            <div className={styles.courseTitle}>
              <span>Graphic Design</span>
            </div>
            <div className={styles.courseMainTitle}>
              <span>Beginner Adobe Illustrator For Graphic  Design</span>
              <span>Design tutorial wil help you learn  quickly and throughly orem ipsum lipsum  as it continue</span>
            </div>
          </div>
            <div className={styles.courseInstructor}>
            <div className={styles.profile}>
              <div className={styles.profileMobile}>
              <img className={styles.avatar} src="https://via.placeholder.com/50" alt="Arian Hok" />
              <span className={styles.name}>Arian Hok</span>
              </div>
<div className={styles.profileMobile}>
<span className={styles.iconStyle}>
      <FontAwesomeIcon color='white' icon={faFile} />
      </span>
      <span className={styles.text}>19</span>
</div>

<div  className={styles.profileMobile}>
<span className={styles.iconStyle}>
      <FontAwesomeIcon color='white' icon={faClock} />
      </span>
      <span className={styles.text}>10h 30m</span>
</div>

<div  className={styles.profileMobile}>
<span className={styles.iconStyle}>
      <FontAwesomeIcon color='white' icon={faUser} />
      </span>
      <span className={styles.text}>18</span>
</div>
        



      <div className={styles.rating}>
        {[...Array(totalStars)].map((_, index) => (

<FontAwesomeIcon key={index} color='#FF9500' className={index < rating ? styles.filledStar : styles.emptyStar} icon={faStar} />
        ))}
        <span className={styles.ratingValue}>({rating.toFixed(1)})</span>
      </div>
    </div>
                {/* <div className={styles.instructorDetails}>
                    <span>
                      Image
                    </span>
                    <span className={styles.instructorName}>
                        Arian Hok
                    </span>
                </div> */}
            </div>

      </div>
      <div className={styles.enrollSection}>
        <div className={styles.enrollDetails}>
            <img src={videoPlaceholder} />
            <div className={styles.costDetails}> 
              <span>Costs:</span>
              <span>$25.00</span>
              <span>$80.00</span>
            </div>
            <div className={styles.enrollButton}>
                <button>ENROLL THIS NOW</button>
            </div>
            <div className={styles.includeCourseWrapper}>
              <div className={styles.includeCourseTitle}>
                <span>Include This Course</span>
              </div>
              <div className={`${styles.details}  ${styles.first}`}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Duration</span>
                <span className={styles.detailText}>5.2 Hours</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Estimated Seats</span>
                <span className={styles.detailText}>250</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Joined</span>
                <span className={styles.detailText}>190</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Language</span>
                <span className={styles.detailText}>English</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Category</span>
                <span className={styles.detailText}>Design</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Share</span>
                <span className={styles.detailText}>5.2 Hours</span>
              </div>
            </div>
        </div>
      </div>
  </div>
  <div className={styles.courseInformation}>
    <div className={styles.tabSectionWrapper}>
    <div className={styles.tabSection}>
    <div className={styles.tabBar}>
        <div
          className={`${styles.tab} ${activeTab === 'CourseInfo' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('CourseInfo')}
        >
          Course Information
        </div>
      </div>
      </div>
    
        {activeTab === 'CourseInfo' ? (
            <div>
              
      <div>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam dolorem neque quae a consectetur, alias dolorum maiores qui quis consequuntur, sed suscipit itaque doloremque commodi inventore rem odit, veritatis quisquam!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius minima quisquam dolor excepturi neque quibusdam consectetur, voluptatem soluta officiis hic veniam magnam necessitatibus aspernatur repellendus culpa cupiditate obcaecati explicabo blanditiis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quis fugiat, voluptates iusto itaque beatae sapiente aliquam cumque doloribus corporis? Facilis eaque porro quibusdam facere id. Alias illum provident laboriosam.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod, veritatis mollitia cumque at nihil cupiditate voluptates illum accusantium accusamus quidem tenetur a debitis rerum qui voluptatibus assumenda ut ex. Omnis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor maiores fugiat sunt quibusdam repellendus nobis sed minima adipisci aut unde quia, dolore vitae similique vero incidunt illo error quam ea Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime nam quasi hic natus. Veniam natus, ducimus incidunt iste voluptas itaque illo delectus unde ex? Quis delectus aliquid sint hic molestias.</p>
      </div>
      <div className={styles.whatYouLearn}>
        <div className={styles.whatYouLearnTitle}>

        </div>
        <div>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat ipsa architecto consequatur sapiente beatae incidunt sequi? Voluptatem ullam doloremque nostrum repellat pariatur atque reiciendis, magni consectetur dicta molestias ipsum quae!</p>
          <div className={styles.whatYouLearnList}>
                <span>Become an UX designer</span>
                <span>You eil be able to add ux designer</span>
                <span> become an ui desinger</span>
          </div>
        </div>
      </div>

      <div className={styles.requirementsSection}>
        <div className={styles.requirementsTitle}>

        </div>
        <div>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat ipsa architecto consequatur sapiente beatae incidunt sequi? Voluptatem ullam doloremque nostrum repellat pariatur atque reiciendis, magni consectetur dicta molestias ipsum quae!</p>
          <div className={styles.requirementsList}>
                <span>Become an UX designer</span>
                <span>You eil be able to add ux designer</span>
                <span> become an ui desinger</span>
          </div>
        </div>
      </div>
              </div>
        ) : ''}

      <h2 className={styles.title}>What Will You Learn?</h2>
      <p className={styles.description}>
        This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs dolor sit amet.
      </p>
      <div className={styles.grid}>
        <div className={styles.item}>
          <span className={styles.checkmark}> <FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Become a UX designer.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> You will be able to add UX design.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Become a UI designer.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Build test a full website design.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Create your first UX brief persona.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> How to use premade UI kits.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Create quick wireframes.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Downloadable exercise files.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Build a UX project from beginning to end.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> Learn to design websites mobile.
        </div>
        <div className={styles.item}>
          <span className={styles.checkmark}><FontAwesomeIcon color='#FF9500' icon={faCheckCircle} /></span> All the techniques used by UX professionals.
        </div>
      </div>

    
    </div>

    <div className={styles.enrollSectionMobileView}>
        <div className={styles.enrollDetails}>
            <img src={videoPlaceholder} />
            <div className={styles.costDetails}> 
              <span>Costs:</span>
              <span>$25.00</span>
              <span>$80.00</span>
            </div>
            <div className={styles.enrollButton}>
                <button>ENROLL THIS NOW</button>
            </div>
            <div className={styles.includeCourseWrapper}>
              <div className={styles.includeCourseTitle}>
                <span>Include This Course</span>
              </div>
              <div className={`${styles.details}  ${styles.first}`}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Duration</span>
                <span className={styles.detailText}>5.2 Hours</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Estimated Seats</span>
                <span className={styles.detailText}>250</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Joined</span>
                <span className={styles.detailText}>190</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Language</span>
                <span className={styles.detailText}>English</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Category</span>
                <span className={styles.detailText}>Design</span>
              </div>
              <div className={styles.details}>
                <span className={styles.detailsIcon}> <FontAwesomeIcon color='#FF9500' icon={faClock} /></span>
                <span className={styles.detailTitle}>Share</span>
                <span className={styles.detailText}>5.2 Hours</span>
              </div>
            </div>
        </div>
      </div>

  </div>

</div>
  );
};

export default CourseDetail;
