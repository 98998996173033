import React ,{useState, useRef, useEffect }from 'react';
import styles from './Accordion.module.css';

const Accordion = ({ title, content, isOpen, onToggle }) => {
  const contentRef = useRef(null);
  const innerRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const contentElement = contentRef.current;
      const innerElement = innerRef.current;
      contentElement.style.height = `${innerElement.scrollHeight}px`;
      contentElement.style.padding = '16px 10px';
    } else {
      const contentElement = contentRef.current;
      contentElement.style.height = '0';
      contentElement.style.padding = '0';
    }
  }, [isOpen]);
  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader} onClick={onToggle}>
        <h3>{title}</h3>
        <button className={styles.accordianButton}><span className={`${isOpen ?  styles.rotate : styles.back }`}>+</span></button>
      </div>
      <div ref={contentRef} className={styles.accordionContent}>
        <div></div>
        <div ref={innerRef} className={styles.accordionContentInner}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
